import React, { RefObject, useRef, useState } from "react";
import logoSvg from "./assets/svgs/logo-gris-640.svg";
import photoSvg from "./assets/svgs/photo.svg";
import welcomeSvg from "./assets/svgs/welcome.svg";
import styles from "./assets/styles/App.module.css";
import {
  aboutUs,
  experiences,
  projectsDetails,
  testimonials,
} from "./textcontentMap";

import afterPng from "./assets/images/soft/after.png";
import angularSvg from "./assets/images/soft/angular.svg";
import babylonjsPng from "./assets/images/soft/babylonjs.png";
import bashSvg from "./assets/images/soft/bash.svg";
import dockerSvg from "./assets/images/soft/docker.svg";
import dotnetSvg from "./assets/images/soft/dotnet.svg";
import figmaSvg from "./assets/images/soft/figma.svg";
import firebaseSvg from "./assets/images/soft/firebase.svg";
import gitlabSvg from "./assets/images/soft/gitlab.svg";
import googleSvg from "./assets/images/soft/google.svg";
import gwtSvg from "./assets/images/soft/gwt.svg";
import ilustradorPng from "./assets/images/soft/ilustrador.png";
import intellijSvg from "./assets/images/soft/intellij.svg";
import invisionPng from "./assets/images/soft/invision.png";
import javaSvg from "./assets/images/soft/java.svg";
import jiraSvg from "./assets/images/soft/jira.svg";
import mariadbSvg from "./assets/images/soft/mariadb.svg";
import maxPng from "./assets/images/soft/max.png";
import mayaPng2 from "./assets/images/soft/Maya.png";
import mongoSvg from "./assets/images/soft/mongo.svg";
import nodeSvg from "./assets/images/soft/node.svg";
import photoshopPng from "./assets/images/soft/photoshop.png";
import postgreSvg from "./assets/images/soft/postgre.svg";
import reactSvg from "./assets/images/soft/react.svg";
import sketchSvg from "./assets/images/soft/sketch.svg";
import springSvg from "./assets/images/soft/spring.svg";
import vizrtSvg from "./assets/images/soft/vizrt.svg";
import vscodeSvg from "./assets/images/soft/vscode.svg";
import xdPng from "./assets/images/soft/xd.png";
import zeplinSvg from "./assets/images/soft/zeplin.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CV_EN from './assets/cv/CV_EN_Aurelio_Munoz_Hijano_24.04.1_LTS.pdf';

import {
  faLink,
  faQuoteLeft,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons";

export const imageFiles = [
  dotnetSvg,
  javaSvg,
  reactSvg,
  angularSvg,
  gwtSvg,
  springSvg,
  babylonjsPng,
  nodeSvg,
  mariadbSvg,
  mongoSvg,
  postgreSvg,
  firebaseSvg,
  bashSvg,
  dockerSvg,
  gitlabSvg,
  googleSvg,
  intellijSvg,
  vscodeSvg,
  jiraSvg,
  invisionPng,
  figmaSvg,
  sketchSvg,
  zeplinSvg,
  maxPng,
  mayaPng2,
  ilustradorPng,
  photoshopPng,
  afterPng,
  xdPng,
  vizrtSvg,
];

const App = () => {
  const aboutMeRef = useRef(null);
  const experienceRef = useRef(null);
  const projectRef = useRef(null);
  const contactMeRef = useRef(null);

  return (
    <div className={styles.container}>
      <div className={styles["light-section"]}>
        <section>
          <Navigation
            aboutMeRef={aboutMeRef}
            experienceRef={experienceRef}
            projectRef={projectRef}
            contactMeRef={contactMeRef}
          />
        </section>
      </div>
      <div className={styles["light-section"]}>
        <section>
          <Intro />
        </section>
      </div>
      <div className={styles["light-section"]}>
        <section>
          <Skills />
        </section>
      </div>
      <div className={styles["dark-section"]}>
        <section ref={experienceRef} className={styles.experience}>
          <Experience />
        </section>
      </div>

      <div className={styles["light-section"]}>
        <section ref={aboutMeRef} className={styles["about-me"]}>
          <AboutMe />
        </section>
      </div>

      <div className={styles["dark-section"]}>
        <section ref={projectRef} className={styles.projects}>
          <Projects />
        </section>
      </div>

      <div className={styles["light-section"]}>
        <section>
          <Testimonial />
        </section>
      </div>

      <div className={styles["light-section"]}>
        <section ref={contactMeRef}>
          <Contact />
        </section>
      </div>

      <Footer />
    </div>
  );
};

const Navigation = ({
  aboutMeRef,
  projectRef,
  experienceRef,
  contactMeRef,
}: {
  // Tipado de las props
  aboutMeRef: RefObject<HTMLElement>;
  projectRef: RefObject<HTMLElement>;
  contactMeRef: RefObject<HTMLElement>;
  experienceRef: RefObject<HTMLElement>;
}) => {
  const scrollToSection = (ref: RefObject<HTMLElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header>
      <img className={styles.logo} src={logoSvg} alt="React Logo" />
      <nav>
        <ul>
          <li onClick={() => scrollToSection(aboutMeRef)}>About Me</li>
          <li onClick={() => scrollToSection(experienceRef)}>
            Experience
          </li>{" "}
          {/* Nuevo botón */}
          <li onClick={() => scrollToSection(projectRef)}>Project</li>
          <li onClick={() => scrollToSection(contactMeRef)}>Contact Me</li>
          <li>
            <a
              href={CV_EN}
              download
              className={styles["resume-button"]}
            >
              Resume
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

const Intro = () => {
  return (
    <>
      <div className={styles.aboutMe}>
        <div>
          <h1>
            Hey!,<strong>It's me, Aurelio,</strong> nice to meet you!
          </h1>
          <h2>FullStack Developer</h2>
        </div>
        <div>
          <img style={{ width: "400px" }} src={welcomeSvg} alt="React Logo" />
        </div>
      </div>
      <p dangerouslySetInnerHTML={{ __html: aboutUs.paragraph }} />
    </>
  );
};

const Skills = () => {
  const images = imageFiles.map((file: string) => (
    <div key={file} className={styles.logoContent}>
      <img
        key={file}
        src={file}
        alt={`Skill: ${file}`}
        className={styles.logoSoft}
      />
    </div>
  ));

  return (
    <>
      <h2>My Skills</h2>
      <div className={styles.skills}>{images}</div>
    </>
  );
};

const Experience = () => {
  return (
    <div className={styles.experience}>
      <h2>My Experience</h2>
      <ul>
        {experiences.map((exp, index) => (
          <li key={index} className={styles.experienceItem}>
            {" "}
            {/* Añadimos una clase para cada elemento de experiencia */}
            <div className={styles.experienceContent}>
              <div className={styles.companyLogo}>
                <img src={exp.logo} alt="React Logo" />
              </div>
              <div>
                <h3>
                  {exp.position} at {exp.company}
                </h3>
                <p className={styles.experienceDate}>{exp.date}</p>
              </div>
            </div>
            <p className={styles.experienceDescription}>{exp.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

const AboutMe = () => {
  return (
    <>
      <h2>About Me</h2>
      <div className={styles.aboutMeContainer}>
        <img
          style={{ width: "350px", marginRight: "20px" }}
          src={photoSvg}
          alt="React Logo"
        />
        <div dangerouslySetInnerHTML={{ __html: aboutUs.paragraph2 }} />
      </div>
    </>
  );
};
const Projects = () => {
  return (
    <div className={styles.projects}>
      <h2>My Projects</h2>
      <div className={styles["project-grid"]}>
        {projectsDetails.map((project, index) => (
          <div
            key={project.number}
            className={`${styles["project-card"]} ${
              index % 2 === 1 ? styles.even : styles.odd
            }`}
          >
            {/* <span className={styles["project-number"]}>{project.number}</span> */}
            <img
              src={project.imageUrl}
              alt={project.title}
              className={styles["project-image"]}
            />{" "}
            <div>
              <h3>{project.title}</h3>
              <span>{project.summary}</span>
              <FontAwesomeIcon
                className={styles["project-link"]}
                icon={faLink}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Testimonial = () => {
  return (
    <section className={styles.testimonial}>
      <h2>My Testimonial</h2>
      <div className={styles.testimonialGrid}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className={styles.testimonialCard}>
            <div className={styles.quoteIcon}>
              <img
                src={testimonial.avatar} // Asegúrate de tener la ruta correcta en tus datos
                alt={`Avatar de ${testimonial.name}`}
                className={styles.testimonialAvatar}
              />
            </div>
            <FontAwesomeIcon className={styles.quoteIcon} icon={faQuoteLeft} />
            <span>{testimonial.text}</span>
            <FontAwesomeIcon className={styles.quoteIcon} icon={faQuoteRight} />
            <div className={styles.testimonialAuthor}>
              <div>
                <h3>{testimonial.name}</h3>
                <p>{testimonial.position}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    url: "",
    message: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value }); // Actualizamos el estado de forma inmutable
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await sendEmail(
      formData.name,
      formData.email,
      formData.url,
      formData.message
    );
    // Puedes añadir aquí lógica para limpiar el formulario después del envío si lo deseas
    setFormData({
      name: "",
      email: "",
      url: "",
      message: "",
    });
  };

  return (
    <>
      <h2>Let's talk for Something special</h2>
      <section className={styles.contact}>
        <div className={styles.contactForm}>
          <form onSubmit={handleSubmit}>
            {" "}
            {/* Cambiamos onClick por onSubmit */}
            <input
              type="text"
              placeholder="Your name"
              name="name" // Añadimos el atributo name para identificar el campo
              value={formData.name} // Vinculamos el valor al estado
              onChange={handleChange} // Manejamos los cambios en el input
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Your website (if exists)"
              name="url"
              value={formData.url}
              onChange={handleChange}
            />
            <textarea
              placeholder="How can I help?"
              rows={4}
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
            <button type="submit">Get In Touch</button>{" "}
          </form>
        </div>
        <div className={styles.contactInfo}>
          <p>
            If you're looking for a dedicated and eager-to-learn developer,
            let's chat! I'd love to collaborate on projects that drive
            technological innovation.
          </p>
          <p>sparanzza@gmail.com</p>  <p>(+34) 654 429 579</p>
          <div className={styles.socialIcons}>
            {/* Agrega aquí los iconos de redes sociales */}
          </div>
        </div>
      </section>
    </>
  );
};

export const sendEmail = async (
  name: string,
  email: string,
  url: string,
  message: string
) => {
  console.log("name", name);
  const emailData = {
    Subject: `Message from ${name} - Email: ${email} - Url: ${url}`,
    Body: `Message content: ${message}`,
  };
  const API_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await fetch(`${API_URL}/email/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Email sent successfully");
          // setIsForm(!isForm);
        } else {
          console.log("Error sending email", response.json());
        }
      })
      .then((data) => console.log(data))
      .catch((error) => console.error("Fetch error:", error));
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      {" "}
      {/* Asegúrate de aplicar la clase styles.footer */}
      <div className={styles.footerContent}>
        {" "}
        {/* Contenedor para alinear los elementos */}
        <div>
          <img src={logoSvg} className={styles.footerLogo} alt="Logo" />
        </div>
        <p>&copy; {currentYear} sparanzza.com | Aurelio Munoz Hijano</p>
      </div>
    </footer>
  );
};

export default App;
