import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// https://codepen.io/simoberny/pen/WMMqwL
// https://codepen.io/alexdevero/pen/aZjLNw
// https://codepen.io/TutulDevs/pen/eYZJmBL
// https://codepen.io/abdelrhmansaid/pen/OJRNOpQ
// https://codepen.io/georgemiro/pen/oxrrwp
// https://nicepage.com/es/html-templates/preview/agencia-de-nika-23743?device=desktop
// https://nicepage.com/es/html-templates/preview/estudio-de-arte-y-pintura-1716155?device=desktop
// https://codepen.io/TurkAysenur/pen/ZEpxeYm
